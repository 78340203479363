import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import api from './api'
import myMixin from './mixin.js';
import VueClipboard from 'vue-clipboard2'
import img from './views/img'
import _ from 'lodash'

// import {
//   Container,
//   Aside,
//   Popover,
//   TabPane,
//   Image,
//   Col,
//   Input,
//   Dialog
// } from 'element-ui';

//Vue.use(Dialog)

Vue.use(VueClipboard)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.api = api
Vue.use(myMixin)
Vue.component('lazy-pic', img)
Vue.prototype._ = _


if(localStorage.getItem('userData')) {
  let userData = JSON.parse(localStorage.getItem('userData'))
  store.commit('setUserData', userData)
}


console.log(store)

Vue.config.silent = true
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
