import axios from "axios";
import store from "./store";
import api from "./api";
import he from "element-ui/src/locale/lang/he";
import { encryptByAES} from "./utils/AESUtil";

export default {

  install(Vue) {

    Vue.mixin({
      data() {
        return {
          imgUrl: '',
          img: {
            'poster': require("@assets/thumb.png"),
            'bigPoster': require("@assets/index.png"),
            'avatar': require('@assets/avatar.png')
          },
        }
      },
      updated() {
      },
      methods: {
        searchItem(val) {
          if (location.href.includes("/search")) {
            this.$emit("search", val)
            this.visible = false
          } else {
            this.$router.push({
              name: "search",
              params: {
                val,
              },
            })
          }
        },
        goDetail(videoId) {
          // this.searchRecordId
          // if (location.href.includes('/detail')) {
          //   location.href = '/detail?id=' + videoId
          // } else {
          //   location.href = '/detail?id=' + videoId
          //   // this.$router.push('/detail?id=' + videoId);
          // }
          // this.$router.push('/detail?id=' + videoId);
          this.$router.push({name:'detail',query: {id:videoId}})


        },
        goCenter(activeName) {
          this.$router.push({
            name: "center-main",
            params: {
              activeName
            },
          })
        },
        getImg(url, width, height) {
/*
          例子：

          原URL：i2.dtslb.com/pic1/b405276170304b3aa80d4b1db05335e3.jpg

          图片处理参数格式：width=70&height=100&imageOptions=smart
          加密后密文：faba13b583e3ca98b990985cdb393376d8d38300b27d4b8241d41a3059e3d54f797115f95c132d358c6fae2e7ae5014b

          https://i0.snowmr.com/pic1/b405276170304b3aa80d4b1db05335e3.jpg?imageParams=faba13b583e3ca98b990985cdb393376d8d38300b27d4b8241d41a3059e3d54f797115f95c132d358c6fae2e7ae5014b
 */
          // 测试通过
          // const a = 'width=70&height=100&imageOptions=smart'
          // console.log(`https://i0.snowmr.com/pic1/b405276170304b3aa80d4b1db05335e3.jpg?imageParams=${encryptByAES(a)}`)

            const params = `width=${width}&height=${height}&imageOptions=smart`
            const imageParams = encryptByAES(params)
          try {
            if (url.indexOf('/') === 0) {
              return `https://i0.snowmr.com${url}?imageParams=${imageParams}`
            } else {
              return `https://i0.snowmr.com/${url}?imageParams=${imageParams}`
            }
          } catch (e) {

          }
        },

      }
    })
  }
}
