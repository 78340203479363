import Vue from "vue";
import VueRouter from "vue-router";

// import Home from "../views/home.vue";
// const Explore = () => import("../views/explore.vue");
// const Recommond  = () =>  ("../views/recommond.vue");
// const Center  = () =>  ("../views/center");
// const Detail  = () =>  ("../views/detail");
// const Search  = () =>  ("../views/search");
// const MoiveGroupDetail  = () =>  ("../views/moive-group-detail");
// const Topic  = () =>  ("../views/topic");
// const TopicDetail  = () =>  ("../views/topic-detail");
// const Login  = () =>  ("../views/login");
// const CenterMain  = () =>  ("../views/center-main");
// const MyExplore  = () =>  ("../views/my-explore");
// const MyHistory  = () =>  ("../views/my-history");
// const MyWatch  = () =>  ("../views/my-watch");
// const Hall  = () =>  ("../views/hall");
// const HallDetail  = () =>  ("../views/hall-detail");
// const SinglePage  = () =>  ("../views/single-page");
// const MainTainPage  = () =>  ("../views/maintain-page")
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 改为按需加载
const routes = [
  {
    path: "/",
    name: "Home",
    component: resolve => require(['../views/home.vue'],resolve),
  },
  {
    path: "/explore",
    name: "Explore",
    component: resolve => require(['../views/explore.vue'],resolve),
  },
  {
    path: "/recommend",
    name: "Recommond",
    component: resolve => require(['../views/recommond.vue'],resolve),
  },
  {
    path: "/center",
    name: "center",
    component: resolve => require(["../views/center"],resolve),
    children: [
      {
        path: "main",
        name: "center-main",
        component: resolve => require(["../views/center-main"],resolve),
      },
      {
        path: "explore",
        name: "my-explore",
        component: resolve => require(["../views/my-explore"],resolve),
      },
      {
        path: "history",
        name: "my-history",
        component: resolve => require(["../views/my-history"],resolve),
      },
      {
        path: "watch",
        name: "my-watch",
        component: resolve => require(["../views/my-watch"],resolve),
      },
    ],
  },
  {
    path: "/detail",
    name: "detail",
    component: resolve => require(['../views/detail'],resolve),
  },
  {
    path: "/search",
    name: "search",
    component: resolve => require(['../views/search'],resolve),
  },
  {
    path: "/moive-group-detail",
    name: "moiveGroup",
    component: resolve => require(['../views/moive-group-detail'],resolve),
  },
  {
    path: "/topic",
    name: "topic",
    component: resolve => require(['../views/topic'],resolve),
  },
  {
    path: "/topic-detail",
    name: "topic-detail",
    component: resolve => require(['../views/topic-detail'],resolve),
  },
  {
    path: "/login",
    name: "login",
    component: resolve => require(['../views/login'],resolve),
  },
  {
    path: "/hall",
    name: "hall",
    component: resolve => require(['../views/hall'],resolve),
  },
  {
    path: "/hall/detail",
    name: "hall-detail",
    component: resolve => require(['../views/hall-detail'],resolve),
  },
  {
    path: '/p/:code',
    name: 'single-page',
    component: resolve => require(['../views/single-page'],resolve)
  },
  {
    path: '/maintain-page',
    name: 'maintain-page',
    component: resolve => require(['../views/maintain-page'],resolve)
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  Vue.prototype.routerFromObject = from;

  next();
});


router.afterEach((to, from) => {
})
export default router;
