import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    maintenanceStatus: false, // 维护状态，如果为真，显示缺省页
    userData: {
      token: '',
      userInfo: {
        avatar: "",
        createdAt: "",
        gender: "",
        mobile: "",
        nickname: "",
        signature: ''
      },
      memberPlan: {
        expiresAt: '',
        name: ''
      }
    },
    recJump: {},
    hotJump: {},
    watchJump: {},
  },
  mutations: {
    setMaintenanceStatus(state, status) {
      state.maintenanceStatus = status
    },
    setUserData(state,userData) {
      state.userData = userData
      localStorage.setItem('userData', JSON.stringify(userData))
    },
    setMemberPlan(state,memberPlan) {
      console.log('setMemberPlan userData' , state.userData)
      state.userData.memberPlan = memberPlan
      localStorage.setItem('userData', JSON.stringify({
        ...state.userData,
        memberPlan
      }))
    },
    resetUserData(state) {
      state.userData = {
        token: '',
        userInfo: {
          avatar: "",
          createdAt: "",
          gender: "",
          mobile: "",
          nickname: "",
          signature: ''
        },
        memberPlan: {
          expiresAt: '',
          name: ''
        },
      }
      localStorage.removeItem('userData')
      console.log('reset')
    },
    setRec(state, params) {
      state.recJump = params
    },
    setHot(state, params) {
      state.hotJump = params
    },
    setWatch(state, params) {
      state.watchJump = params
    },
  },
  actions: {
  },
  modules: {

  }
})
