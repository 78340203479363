<template>
	<div class="left">
		<div class="logo" @click="goHome">
			<img src="@assets/aside/logo.png" alt="" />
		</div>
		<div class="list">
			<div
				class="list-item"
				v-for="(item, index) in routeList"
				:key="index"
				:class="{ 'list-item-select': item.name == selected }"
				@click="clickRoute(item)"
			>
				<div class="list-item-title" v-show="item.name == '我的栏目' ? isLogin : true">
					<img :src="item.name == selected ? item.selectedIcon : item.icon" alt="" v-if="item.icon" />
					<span :style="{ color: item.icon ? '#797A8E' : '#B6B7C3' }">{{ item.name }}</span>
				</div>
				<div
					class="list-item-child"
					@click="clickRoute(child)"
					v-for="(child, childIndex) in item.children"
					:key="childIndex"
					:class="{ 'list-item-select': child.name == selected }"
					v-show="item.name == '我的栏目' ? isLogin : true"
				>
					<el-popover
						placement="right-start"
						trigger="click"
						v-show="child.isHover"
						style="
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            "
					>
						<div class="list-item-child-wrapper" slot="reference">
							<img
								:src="child.name == selected ? child.selectedIcon : child.icon"
								alt=""
								v-if="child.icon"
							/>
							<span :style="{ color: '#797A8E' }">{{ child.name }}</span>
						</div>
						<div class="right-hover-list">
							<div
								class="right-hover-list-item"
								v-for="(area, areaIndex) in child.list"
								:key="areaIndex"
								@click="goRec(area, child)"
							>
								{{ area.name }}
							</div>
						</div>
					</el-popover>

					<div class="list-item-child-wrapper" v-show="!child.isHover">
						<img :src="child.name == selected ? child.selectedIcon : child.icon" alt="" v-if="child.icon" />
						<span :style="{ color: '#797A8E' }">{{ child.name }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="jump2">
<!--			联系我们文案调整为：APP下载，点击使用浏览器新标签页打开：https://b16.red/Meh44-->
<!--			投诉建议文案调整为：帮助中心，点击使用浏览器新标签页打开：https://b16.red/p7sk3-->
<!--			用户协议，点击使用浏览器新标签页打开：https://b16.red/QejPd-->
<!--			隐私权政策文案调整为：隐私政策，点击使用浏览器新标签页打开：https://b16.red/3Cz9r-->
			<div>
				<a href="https://app.biuv.net/" target="_blank">APP下载</a>
			</div>
			<div>
				<a href="https://support.biuv.net/" target="_blank">帮助中心</a>
			</div>
			<div>
				<a href="https://support.biuv.net/protocol" target="_blank">用户协议</a>
			</div>
			<div>
				<a href="https://support.biuv.net/privacy" target="_blank">隐私政策</a>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			selected: "主页",
			routeList: [
				{
					url: "/",
					name: "主页",
					icon: require("@assets/aside/aside1.png"),
					selectedIcon: require("@assets/aside/aside1s.png"),
				},
				{
					url: "/explore",
					name: "开始探索",
					icon: require("@assets/aside/aside2.png"),
					selectedIcon: require("@assets/aside/aside2s.png"),
				},
				{
					url: "/hall",
					name: "影厅放映",
					icon: require("@assets/aside/aside3.png"),
					selectedIcon: require("@assets/aside/aside3s.png"),
				},
				{
					url: "/recommend",
					name: "频道推荐",
					icon: require("@assets/aside/aside4.png"),
					selectedIcon: require("@assets/aside/aside4s.png"),
				},
				{
					url: "/topic",
					name: "主题推荐",
					icon: require("@assets/aside/aside5.png"),
					selectedIcon: require("@assets/aside/aside5s.png"),
				},
				{
					name: "地区和类型",
					children: [
						{
							url: "",
							icon: require("@assets/aside/aside6.png"),
							selectedIcon: require("@assets/aside/aside6s.png"),
							name: "地区",
							isHover: true,
						},
						{
							url: "",
							icon: require("@assets/aside/aside6.png"),
							selectedIcon: require("@assets/aside/aside6s.png"),
							name: "类型",
							isHover: true,
						},
					],
				},
				{
					name: "热门和推荐",
					children: [],
				},
				{
					name: "我的栏目",
					isShow: !this.isLogin,
					children: [
						{
							url: "/center",
							icon: require("@assets/aside/aside7.png"),
							selectedIcon: require("@assets/aside/aside7s.png"),
							name: "观看记录",
						},
						{
							url: "/center",
							icon: require("@assets/aside/aside8.png"),
							selectedIcon: require("@assets/aside/aside8s.png"),
							name: "我的看单",
						},
						{
							url: "/center",
							icon: require("@assets/aside/aside9.png"),
							selectedIcon: require("@assets/aside/aside9s.png"),
							name: "我的探索",
						},
					],
				},
			],
			area: [],
			type: [],
		}
	},
	computed: {
		isLogin() {
			console.log(this.$store.state.userData)
			return this.$store.state.userData && this.$store.state.userData.token
		},
		userData() {
			return this.$store.state.userData
		},
	},
	methods: {
		goHome() {
			this.$router.push("/")
		},
		clickRoute(item) {
			if (item && !item.children) this.selected = item.name
			console.log(item)
			if (item.channelId) {
				if (location.href.includes("/topic-detail")) {
					this.$store.commit("setHot", item)
				} else {
					this.$router.push({
						name: "topic-detail",
						params: {
							item,
						},
					})
				}
			}
			if (item.url) {
				if (item.url == "/center") {
					if (location.href.includes("/center")) {
						if (item.name == "观看记录") {
							this.$store.commit("setWatch", 1)
						}
						if (item.name == "我的看单") {
							this.$store.commit("setWatch", 2)
						}
						if (item.name == "我的探索") {
							this.$store.commit("setWatch", 3)
						}
					} else {
						if (item.name == "观看记录") {
							this.$router.push({
								name: "my-history",
								params: {
									activeName: 1,
								},
							})
						}
						if (item.name == "我的看单") {
							this.$router.push({
								name: "my-watch",
								params: {
									activeName: 2,
								},
							})
						}
						if (item.name == "我的探索") {
							this.$router.push({
								name: "my-explore",
								params: {
									activeName: 3,
								},
							})
						}
					}
				} else {
					this.$router.push(item.url)
					//location.href=item.url
				}
			}
		},

		async init() {
			this.api.setGray(true)
			if (localStorage.getExpire("BIYOUCONFIG")) {
				const data = JSON.parse(localStorage.getExpire("BIYOUCONFIG"))
				this.setConfig(data)
			} else {
				const result = await this.api.getConfig()
				console.log(result.data)
				if(result.code == 11) {
					this.$store.commit("setMaintenanceStatus", true)
					// 跳缺省维护页面
					this.$router.push({
						name: 'maintain-page'
					})
					this.api.setGray(false)
					// 全站维护
					return
				}
				if (result.code == 1) {
					localStorage.setExpire("BIYOUCONFIG", JSON.stringify(result.data), 1000 * 60)
					this.setConfig(result.data)
				}
			}
		},
		setConfig(data) {
			console.log(data)

			// 设置标题
			document.title = `${data.site.siteName} ${data.site.siteSlogan}`
			console.log(data)
			this.api.setGray(data.site.gray)

			this.routeList[5].children[0].list = data.area.map((i) => {
				i.name = i.areaName
				return i
			})

			this.routeList[5].children[1].list = data.type.map((i) => {
				i.name = i.typeName

				return i
			})
			this.routeList[6].children = data.sidebar.channels.map((i) => {
				return {
					icon: require("@assets/aside/aside6.png"),
					selectedIcon: require("@assets/aside/aside6s.png"),
					name: i.channelName,
					...i,
				}
			})
		},
		initConfig() {
			Storage.prototype.setExpire = (key, value, expire) => {
				let obj = {
					data: value,
					time: Date.now(),
					expire: expire,
				}
				localStorage.setItem(key, JSON.stringify(obj))
			}
			Storage.prototype.getExpire = (key) => {
				let val = localStorage.getItem(key)
				if (!val) {
					return val
				}
				val = JSON.parse(val)
				if (Date.now() - val.time > val.expire) {
					localStorage.removeItem(key)
					return null
				}
				return val.data
			}
		},
		goRec(area, item) {
			console.log(area, item)
			if (location.href.includes("/recommond")) {
				this.$store.commit("setRec", {
					area,
					item,
				})
			} else {
				this.$router.push({
					name: "Recommond",
					params: {
						area,
						item,
					},
				})
			}
			for (let ele of document.querySelectorAll(".el-popover.el-popper")) {
				ele.style.display = "none"
			}
		},
	},
	mounted() {
		this.initConfig()
		this.init()

		for (let item of this.routeList) {
			if (item.url && location.href.includes(item.url)) {
				this.selected = item.name
				console.log(item)
			}
		}
	},
}
</script>
<style lang="scss">
.left::-webkit-scrollbar {
	display: none; /* Chrome Safari */
}

.left {
	background: #1a1936;
	width: 100%;
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	.logo {
		cursor: pointer;
		width: 100%;
		height: 100px;
		padding-top: 20px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.list {
		margin-top: 40px;
		.list-item {
			.list-item-title {
				height: 60px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 500;
				padding-left: 30px;
				img {
					width: 30px;
					height: 30px;
					margin-right: 10px;
				}
				cursor: pointer;
			}
			margin: 0 30px;
			width: 260px;
			min-height: 60px;
			.list-item-child {
				padding-left: 30px;
				height: 60px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				cursor: pointer;
				.list-item-child-wrapper {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					height: 100%;
					img {
						width: 30px;
						height: 30px;
						margin-right: 10px;
					}
				}
			}
		}
	}
	.jump {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		margin: 40px 0px;
		margin-left: 30px;
		div {
			width: 120px;
			height: 40px;
			font-size: 13px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 18px;
			color: #797a8e;
			text-align: center;
			line-height: 40px;
		}
		div:hover {
			cursor: pointer;
			color: #ff3465;
		}

	}
	.list-item-select {
		.list-item-title {
			span {
				color: white !important;
			}
		}
		span {
			color: white !important;
		}
		background: #ff3465;
		opacity: 1;
		border-radius: 30px;
	}
}
.right-hover-list {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;

	max-width: 500px;
	flex-wrap: wrap;
	.right-hover-list-item {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 20px;
		color: #797a8e;
		padding: 10px 20px;
		margin-right: 10px;
		margin-left: 10px;
		cursor: pointer;
		min-width: 50px;
	}
	.right-hover-list-item:hover {
		color: rgba(255, 52, 101, 1);
	}
}

@media screen and (max-width: 1680px) {
	.el-aside {
		width: 240px !important;
	}
	.left {
		.logo {
			height: 70px;
		}
		.list {
			.list-item {
				width: 190px;
				min-height: 50px;
				margin: 0 20px;
				.list-item-title {
					height: 50px;

					img {
						width: 20px;
						height: 20px;
					}
					span {
						font-size: 14px;
					}
				}
				.list-item-child {
					height: 50px;
					.list-item-child-wrapper {
						img {
							width: 20px;
							height: 20px;
						}
						span {
							font-size: 14px;
						}
					}
				}
			}
		}
		.jump {
			margin: 20px 0;
			div {
				width: 110px;
			}
		}

	}
}

.jump2 {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	margin: 40px 0px;
	margin-left: 30px;

	div {
		width: 120px;
		height: 40px;
		font-size: 13px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 18px;
		color: #797a8e;
		text-align: center;
		line-height: 40px;
	}
	a{
		color: #797a8e;
		text-decoration:none
	}
	a:hover {
		cursor: pointer;
		color: #ff3465;
	}
}
</style>
