<template>
  <el-image  :key="array[0]+array[1]+array[2]" lazy :src="getImg(array[0], array[1], array[2])" :onerror="img[type]" style="width:100%;height: 100%;display: block;"  :scrollContainer="isWrapper ? wrapperKey[isWrapper] : null" >
    <div slot="placeholder" class="image-slot">
      <img :src="img[type]" alt="" >
    </div>
  </el-image>
</template>

<script>
export default {
  name: "img",
  mounted() {
  },
  data() {
    return {
      backgroundImage: require("@assets/poster-main-image.jpg"),
      img: {
        'poster': require("@assets/poster-main-image.jpg"),
        'bigPoster': require("@assets/poster-big-image.jpg"),
        'avatar': require('@assets/avatar.png')
      },
      wrapperKey: {
        a: document.querySelector('.child-view')
      },

    }
  },
  props: {
    isHome: {
      type: Boolean,
      default: false,
    },
    array: {
      type: Array,
      default: ['','','']
    },
    type: {
      type: String,
      default: 'poster'
    },
    isWrapper: {
      type: String,
      default: ''
    }
  },
}
</script>

<style lang="scss">
.image-slot {
  width:100%;
  height:100%;
  img {
    width:100%;
    display: block;
  }
}
</style>
