/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";
import store from "./store/index";
import router from './router/index'
// 环境的切换
// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = '/api';
// } else if (process.env.NODE_ENV == 'debug') {
//     axios.defaults.baseURL = '';
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL = 'http://api.123dailu.com/';
// }




if (process.env.NODE_ENV == 'development') {    // 开发环境
    axios.defaults.baseURL = "/web";
} else if (process.env.NODE_ENV == 'production') {  // 生产环境
    axios.defaults.baseURL = "https://app.snowmr.com/web";
}
//axios.defaults.baseURL = "https://app.snowmr.com/web";

axios.defaults.timeout = 10000;

const header = {
    platform: "web",
};

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

function get(url, params) {
    //let data=params;

    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: store.state.userData.token,
                ...header,
            },
            params,
            //data:QS.stringify(params)
        })
            .then((res) => {
                const code = res.data.code
                if (code === -1 || code === -2 || code === 11) {
                    checkCode(code)
                } else {
                    resolve(res.data);
                }
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
function post(url, params) {
    let data = params;
    return new Promise((resolve, reject) => {
        //axios.post(url, QS.stringify(params))
        axios({
            method: "post",
            url: url,
            headers: {
                ...header,
                Authorization: "Bearer " + store.state.userData.token,
            },
            data: params,
        })
            .then((res) => {

                const code = res.data.code
                if (code === -1 || code === -2) {
                    checkCode(code)
                } else {
                    resolve(res.data);
                }

            })
            .catch((err) => {
                reject(err.data);
            });
    });
}


function checkCode(code) {
    if(code === 11) {
        router.push({
            name: 'maintain-page',
            params: {
                code
            }
        })
    } else {
        console.log(router)
        store.commit('resetUserData')
        router.push({
            name: 'login',
            params: {
                code
            }
        })
    }

}

const api = {};
api.headers = {
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
}
// 首页
api.getConfig = () => get("/site/config");
api.getRecommond = (p) => get("/recommend", p);
api.getHome = () => get("/index");
api.getHomeblock = () => get('/announcement')
// 电影
api.getMoiveDetail = (p) => get("/movie/detail", p);
api.getMoiveComment = (p) => get("/movie/comment", p);
api.getMoiveRecommond = (p) => get("/movie/recommend", p);
api.setMoiveComment = (p) => post("/movie/comment", p);

api.moiveClickLike = (p) => get("/movie/like", p);
api.moiveClickDisLike = (p) => get("/movie/dislike", p);
api.getRecMoive = (p) => get("/movie/recommend", p);
api.goodComment = (p) => post("/movie/comment/like", p);
api.setTopComment = (p) => post("/movie/comment/toggleSticky", p);
api.deleteComment = (p) => post("/movie/comment/delete", p);
api.setUserHistory = (p) => post('/user/series/add', p)
// 电视剧
api.getPlayDetail = () => get("/play/detail");
api.setPlayComment = (p) => get("/play/comment", p);
api.getPlayRecommond = (p) => get("/play/recommend", p);

api.playClickLike = (p) => get("/play/like", p);
api.playClickDisLike = (p) => get("/play/dislike", p);

// 电影电视剧通用
api.addPlayList = (p) => post("/playlist/add", p);
api.getFeedBack = (p) => get("/feedback/list", p);
api.addFeedBack = (p) => post("/feedback", p);
// 登录
api.sendCode = (p) => post("/user/login/sendCode", p);
api.loginByPassword = (p) => post("/user/login/password", p);
api.loginByCode = (p) => post("/user/login/code", p);
api.sendCodeForForget = (p) => post("/user/forgetPass/sendCode", p);
api.editPassword = (p) => post("/user/forgetPass/updatePassword", p);
// 搜索
api.search = (p) => get("/search", p);
api.searchRecord = (searchRecordId, videoId) => post(`/search/record?searchRecordId=${searchRecordId}&videoId=${videoId}`)
api.getSearchRank = (p) => get("/search/rank", p);
// 频道
api.getChannel = (p) => get("/channelType", p);
api.getChannelList = (p) => post("/channel", p);
// 探索
api.getExplore = (p) => get("/explore/index", p);
api.getExploreSearch = (p) => get("/explore/search", p);
api.addExplore = (p) => post("/explore/add", p);
api.deleteExploreAll = (p) => post('/explore/delete', p)
api.deleteExplore = (p) => post('/explore/delete', p)

// user
api.getUserInfo = () => get("/user/info");
api.setUserInfo = (p) => post("/user/info", p);
api.getMyExplore = (p) => get("/explore/list", p);
api.setPhone = (p) => post("/user/mobile/update", p);
api.getEditPhoneCode = (p) => post("/user/mobile/sendCode", p);
api.removeWatch = (p) => post("/user/playlist/delete", p);

api.getWatchList = (p) => get("/user/series/list", p);
// 订阅
api.getPlan = () => get("/plans");
api.getWechat = (p) => post("/plan/pay/wechat", p);
api.getAlipay = (p) => post("/plan/pay/alipay", p);
api.getOrderStatus = (orderNo) =>get(`/plan/order/status?orderNo=${orderNo}`);
// 电影详情
api.getMoiveDetail = (p) => get("/movie/detail", p);
api.clickGood = (p) => post("/movie/like", p);
api.clickUnGood = (p) => post("/movie/dislike", p);
api.getComment = (p) => get("/movie/comment", p);
api.setPlayList = (p) => post("/user/playlist/add", p);
api.getPlayList = () => get("/user/playlist/list");
api.deletePlayList = (p) => post("/user/playlist/delete", p);
// 推荐
api.getTopic = () => get("/topic");
api.getTopicDetail = (p) => get("/datasource", p);
// URL
// upload
api.uploadApi = "https://app.snowmr.com/web" + "/file/upload";
api.removeHistory = p => post('/user/series/delete', p)
api.removeAllHistory = () => post('/user/series/delete', {id: null})
// 影厅
api.getHallList = p => get('/hall/list', p)
api.createHall = p => post('/hall/create', p)
api.joinHall = p => post('/hall/join', p)
api.switchPublic = (id) => post('/hall/toggleStatus', {hallId: id})
api.hallRoomCallBackStatus = (hallId) => post('/hall/hallRoomCallBackStatus', {
    hallId,
    roomCreateStatus: 1
})
api.hallPushInfo =  p => post(`/hall/pushInfo`, p)
api.hallPlayInfo = (hallId) => get(`/hall/playInfo?hallId=${hallId}`)
api.kickOff = (p) => post('/hall/hallAddUserToBlacklist', p)
api.getRandomRoom = () => get('/hall/randomEntry')
api.singlePage = (code) => get(`/page/${code}`);


api.thumbor = (url, width, height) => get(`/thumbor?url=${url}&width=${width}&height=${height}`)
// Function
api.setGray = (isSet) => {
    if (isSet) {
        const styleLink = `
        
            body {
                filter: grayscale(100%);
                -webkit-filter: grayscale(100%);
                -moz-filter: grayscale(100%);
                //-ms-filter: grayscale(100%);
                -o-filter: grayscale(100%);
                filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
                -webkit-filter: grayscale(1);
            }
        
        `;
        const styleElement = document.createElement("style");
        styleElement.innerHTML = styleLink;
        styleElement.id = "gray-body";
        document.getElementsByTagName("head")[0].appendChild(styleElement);

        console.log(styleElement);
    } else {
        document.querySelector("#gray-body").remove();
    }
};
export default api;
