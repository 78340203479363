<template>
	<div id="app">
		<el-container key="app" style="width: 100%; height: 100%;">
			<el-aside width="320px">
				<aside-bar></aside-bar>
			</el-aside>
			<el-container key="1-1">
				<el-main style="padding: 0;position: relative;min-width:867px;height: 100vh;overflow-y: auto;background: #12142e;">
						<router-view class="child-view"></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>
<script>
import asideBar from "./views/aside-bar"
export default {
	components: {
		asideBar,
	},
	data() {
		return {}
	},
	mounted() {
	},
	watch: {},
}
</script>
<style>
.child-view {
	/* position: absolute; */
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}


</style>
