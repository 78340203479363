import CryptoJS from 'crypto-js'

const ckey = 'evFJpjU7Vx8AiK1k'
const civ = 'WHHe7TzaVh9GDb2n'

export function encryptByAES( data ) {
  const srcs = CryptoJS.enc.Utf8.parse(data)
  const key = CryptoJS.enc.Utf8.parse(ckey)
  const iv = CryptoJS.enc.Utf8.parse(civ)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString()
}


export function decryptByAES( data ) {

  const key = CryptoJS.enc.Utf8.parse(ckey)
  const iv = CryptoJS.enc.Utf8.parse(civ)
  const encrypted = CryptoJS.AES.decrypt(data, key, {
    iv: iv,
    padding: CryptoJS.pad.ZeroPadding
  })
  return encrypted.ciphertext.toString()
}
